[data-nav-layout="horizontal"] {
  @media (min-width: 992px) {
    .sidemenu-layout-styles {
      display: none;
    }
    .main-menu {
      &-container {
        display: inline-flex;
      }
      display: flex !important;
      transition: all 0.5s ease;
      // flex-wrap: wrap; // To enable wrap version of horizontal
    }
    .side-menu__label .badge {
      display: none;
    }
    .mega-menu {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
    }
    .app-header {
      padding-inline-start: 0 !important;
      z-index: 104;
      box-shadow: none;
    }
    .app-content {
      min-height: calc(100vh - 10.5rem);
      margin-block-start: 7rem;
      margin-inline-start: 0rem !important;
      > .container-fluid {
        width: 94%;
        margin-inline-start: auto;
        margin-inline-end: auto;
      }
    }
    .app-sidebar {
      .main-sidebar,
      .simplebar-mask {
        overflow: visible;
      }
      .main-menu > .slide {
        margin: 0 0.1875rem;
      }
      .main-sidebar {
        box-shadow: none;
      }
    }
    .main-sidebar .simplebar-vertical {
      visibility: hidden !important;
    }
    .main-sidebar,
    .main-header-container {
      width: 94%;
      margin: 0 auto;
    }
    .horizontal-logo {
      padding: 0.85rem 0;
      display: block;
      @media (min-width: 992px) {
        .header-logo {
          img {
            height: 2rem;
            line-height: 2rem;
          }
          .desktop-logo {
            display: block;
          }
          .desktop-dark,
          .toggle-logo,
          .toggle-dark,
          .desktop-white,
          .toggle-white {
            display: none;
          }
        }
      }
    }
    .main-header-container .sidemenu-toggle {
      display: none;
    }
    .app-sidebar {
      width: 100% !important;
      height: 3rem;
      border-block-end: 1px solid $menu-border-color;
      border-inline-end: 0 !important;
      inset-block-start: 3.75rem;
      .slide-menu.child1,
      .slide-menu.child2,
      .slide-menu.child3 {
        li {
          padding-inline-start: 0.5rem;
        }
        .side-menu__item:before {
          inset-block-start: 1rem;
        }
      }
      .simplebar-wrapper{
        margin: 0 !important;
      }
      .simplebar-content {
        padding: 0 !important;
        overflow: hidden;
      }
      .simplebar-content-wrapper {
        overflow: visible !important;
        height: auto;
      }
      .main-sidebar {
        height: 3rem !important;
        padding: 0;
      }
      .slide.has-sub .slide-menu {
        &.child1 {
          inset-inline-start: 1.5rem !important;
          padding: 0.1875rem 0;
        }
        &.child2,
        &.child3 {
          inset-inline-end: 100% !important;
          padding: 0.1875rem 0;
        }
        &.active {
          inset-inline-start: auto !important;
          inset-inline-end: auto !important;
        }
      }
      .slide-menu.child1 {
        position: absolute !important;
      }
      .side-menu__item {
        width: 100%;
        display: flex;
        border-radius: 0;
        padding: 0.92rem;
      }
      .side-menu__angle {
        inset-inline-end: 0.45rem;
        display: block;
      }
      .side-menu__icon {
        margin-inline-end: 0.25rem;
        margin-block-end: 0;
      }
      .slide.has-sub .slide-menu.child1 {
        background-color: var(--menu-bg);
        inset-block-start: 100% !important;
        box-shadow: 0.063rem 0.188rem 0.5rem $black-1;
        min-width: 12rem;
      }
      .slide {
        padding: 0;
      }
      .slide-menu {
        &.child1 {
          border-radius: 0 0 0.25rem 0.25rem;
          .slide.has-sub,
          .slide {
            width: 100%;
            display: flex;
            padding: 0 0.1875rem;
          }
        }
        &.child2,
        &.child3 {
          border-radius: 0.25rem;
        }
      }
      .slide,
      .slide.has-sub {
        position: static;
      }
      .main-menu {
        margin-block-end: 0;
      }
    }
    .main-sidebar-header {
      display: none !important;
    }
    .main-sidebar {
      margin-block-start: 0;
      padding-block-end: 3rem;
    }
    .slide__category {
      display: none;
    }
    /* horizontal arrows */
    .main-menu-container .slide-left {
      inset-inline-start: 1.438rem;
    }
    .main-menu-container .slide-left,
    .main-menu-container .slide-right {
      position: absolute;
      top: 0.563rem;
      padding: 0.375rem;
      color: $custom-white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      cursor: pointer;
      border: 1px solid $default-border;
      border-radius: 3.125rem;
    }

    .main-menu-container .slide-left,
    .main-menu-container .slide-right {
      position: absolute;
      top: 0.313rem;
      padding: 0.375rem;
      color: $custom-white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      cursor: pointer;
      border: 1px solid $default-border;
      border-radius: 3.125rem;
      background: $custom-white;
    }

    .main-menu-container {
      .slide-right {
        inset-inline-end: -2%;
      }
      .slide-left {
        inset-inline-start: -2%;
      }
    }
    &[page-style="classic"] {
      .app-sidebar {
        border-block-end: 1px solid $default-border;
      }
    }
    &[dir="rtl"] {
      .main-menu-container {
        .slide-right,
        .slide-left {
          transform: rotate(180deg);
        }
      }
    }
    &[data-menu-styles="transparent"][data-theme-mode="dark"][data-icon-overlay="open"]
      .app-sidebar:hover {
      backdrop-filter: blur(0px);
    }
    &[data-menu-styles="light"] .main-menu-container {
      .slide-right,
      .slide-left {
        --custom-white: #fff;
        --default-border: #f3f3f3;
      }
    }
    &[data-page-style="modern"] {
      .app-sidebar {
        border-block-end: 1px solid $header-border-color;
      }
    }
    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
      .app-sidebar {
        backdrop-filter: blur(30px);
      }
    }
    &[data-nav-style="menu-click"],
    &[data-nav-style="menu-hover"],
    &[data-nav-style="icon-click"],
    &[data-nav-style="icon-hover"] {
      .app-sidebar {
        .slide.has-sub .slide-menu {
            &.child1 {
                &.force-left {
                  inset-inline-end: 0 !important;
                }
                
            }
          &.child2,
          &.child3 {
            &.force-left {
              inset-inline-start: -100% !important;
            }
          }
        }
      }
    }
  }
}
